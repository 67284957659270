import React, { useState } from 'react';
import { validateEmail } from '../utils/helpers';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Button from '@mui/joy/Button';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contactTitle: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '0',
  },
  input: {
    marginBottom: '16px',
    width: '600px',
  },
  button: {
    marginBottom: '16px',
    color: 'black',
    backgroundColor: '#de9151ff',
    '&:hover': {
      backgroundColor: '#ad9e91',
      transform: 'scale(1.02)',
      color: 'white',
    },
  },
};

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [messageErrorMessage, setMessageErrorMessage] = useState('');

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailBlur = (e) => {
    if (!validateEmail(e.target.value)) {
      setErrorMessage('Email is invalid');
    } else {
      setErrorMessage('');
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    if (e.target.value) {
      setMessageErrorMessage('');
    } else {
      setMessageErrorMessage('Message is required');
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setErrorMessage('Email is invalid');
      return;
    }
    if (!message) {
      setMessageErrorMessage('Message is required');
      return;
    }
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <Grid container style={styles.container}>
      <Grid item xs={12} style={styles.title}>
        <h2 style={styles.contactTitle}>Contact</h2>
        <p>
          Get in touch via the form below, or by emailing{' '}
          <Link href='mailto:oliviasylee@gmail.com' underline='none' color='#ed6c02'>
            <b>oliviasylee@gmail.com</b> 
          </Link>
        </p>

        <TextField
          required
          label='Name'
          variant='filled'
          value={name}
          onChange={handleNameChange}
          style={styles.input}
        />
        <TextField
          required
          label='Email Address'
          variant='filled'
          value={email}
          error={!!errorMessage}
          helperText={errorMessage}
          onChange={handleEmailChange}
          onBlur={handleEmailBlur}
          style={styles.input}
        />
        <TextField
          required
          label='Message'
          multiline
          minRows={4}
          variant='filled'
          value={message}
          error={!!messageErrorMessage}
          helperText={messageErrorMessage}
          onChange={handleMessageChange}
          style={styles.input}
        />
        <Button
          className='button'
          onClick={handleFormSubmit}
          size='lg'
          variant='contained'
          sx={styles.button}>
            Submit
        </Button>
      </Grid>
    </Grid>
  );
}

export default Contact;