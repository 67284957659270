import React from 'react';
import Container from '@mui/material/Container';
import { NavLink } from 'react-router-dom';

function Nav({ currentPage, handlePageChange }) {
  return (
    <Container>
      <ul className='nav'>
        <li>
          <NavLink exact to='/' onClick={() => handlePageChange('AboutMe')} activeClassName='nav-link active' className={currentPage === 'AboutMe' ? 'nav-link active' : 'nav-link'}>
            About Me
          </NavLink>
        </li>
        <li>
          <NavLink to='/portfolio' onClick={() => handlePageChange('Portfolio')} activeClassName='nav-link active' className={currentPage === 'Portfolio' ? 'nav-link active' : 'nav-link'}>
            Portfolio
          </NavLink>
        </li>
        <li>
          <NavLink to='/contact' onClick={() => handlePageChange('Contact')} activeClassName='nav-link active' className={currentPage === 'Contact' ? 'nav-link active' : 'nav-link'}>
            Contact
          </NavLink>
        </li>
        <li>
          <NavLink to='/resume' onClick={() => handlePageChange('Resume')} activeClassName='nav-link active' className={currentPage === 'Resume' ? 'nav-link active' : 'nav-link'}>
            Resume
          </NavLink>
        </li>
      </ul>
      </Container>
  );
}

export default Nav;