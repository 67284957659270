import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { CardActionArea } from '@mui/material';

const styles = {
  button: {
    marginBottom: '16px',
    color: 'black',
    backgroundColor: '#de9151ff',
    '&:hover': {
      backgroundColor: '#ad9e91',
      transform: 'scale(1.02)',
      color: 'white',
    },
  },
};

function Project({ project }) {
  return (
    <div style={{ flex: '0 0 calc(33.33% - 10px)', marginBottom: '20px' }}>
      <Card sx={{ maxWidth: 350 }}>
        <CardActionArea component="a" href={project.liveLink} target="_blank">
          <CardMedia
            component='img'
            alt={project.title}
            height='173'
            image={`${project.image}`}/>
            <CardContent style={{ textAlign: 'center', padding:'0' }}>
              <h3>{project.title}</h3>
            </CardContent>
          </CardActionArea>
            <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                size='small'
                component='a'
                href={project.codeLink}
                target='_blank'
                rel='noopener noreferrer'
                variant='contained'
                sx={styles.button}>
                  Code
              </Button>
              <Button
                size='small'
                component='a'
                href={project.liveLink}
                target='_blank'
                rel='noopener noreferrer'
                variant='contained'
                sx={styles.button}>
                  Live
              </Button>
          </CardActions>
      </Card>
    </div>
  );
}

export default Project;