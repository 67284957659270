import React, { useState } from 'react';
import { 
  Toolbar, 
  IconButton, 
  Grid, 
  useMediaQuery, 
  useTheme, 
  Box, 
  Drawer, 
  List, 
  ListItem, 
  ListItemButton, 
  ListItemText, 
  Typography, 
  Divider  
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Nav from './Nav';
import { NavLink } from 'react-router-dom';

import Logo from '../images/oslee-logo.png'

const navItems = [
  { name: 'About Me', path: '/' },
  { name: 'Portfolio', path: '/portfolio' },
  { name: 'Contact', path: '/contact' },
  { name: 'Resume', path: '/resume' },
];

function Header({ currentPage, handlePageChange }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down(1076));

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <header className='header'>
      <Toolbar>
        <Grid container alignItems='center'>
          <Grid item xs>
            <NavLink
              exact
              to='/'
              onClick={() => handlePageChange('AboutMe')}
              activeClassName='nav-link active'
              className='nav-link'
            >
            <img src={Logo} className='logo' alt='Olivia Seonyeong Lee' />
            </NavLink>
          </Grid>
          <Grid item>
            {isMediumScreen ? (
              <IconButton onClick={toggleDrawer} color='inherit' aria-label='menu'>
                <MenuIcon />
              </IconButton>
            ) : (
              <Box>
                <Nav currentPage={currentPage} handlePageChange={handlePageChange} />
              </Box>
            )}
          </Grid>
        </Grid>
      </Toolbar>
      <Drawer 
        anchor='right' 
        open={isDrawerOpen} 
        onClose={toggleDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            bgcolor: '#f4efe6ff'
          }
        }}>
        <Box sx={{ width: 300, bgcolor: '#f4efe6ff' }} onClick={toggleDrawer} >
          <Typography
            variant='h6'
            component='div'
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block', fontWeight: 'bold' }, textAlign: 'center', py: 2 }}>
            Olivia Lee
          </Typography>
          <Divider />
          <List>
            {navItems.map((item) => (
              <ListItem key={item.name} disablePadding>
                <ListItemButton sx={{ textAlign: 'center', ml: -3 }} component={NavLink} to={item.path}>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </header>
  );
}

export default Header;