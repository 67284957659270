import React, { useState } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import AboutMe from './components/AboutMe';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Resume from './components/Resume';
import Container from '@mui/material/Container';

function App() {
  const [currentPage, setCurrentPage] = useState('AboutMe');

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Router>
      <Container maxWidth='lg'>
        <Header currentPage={currentPage} handlePageChange={handlePageChange} />
        <Switch>
          <Route exact path='/' component={AboutMe} />
          <Route path='/about-me' component={AboutMe} />
          <Route path='/portfolio' component={Portfolio} />
          <Route path='/contact' component={Contact} />
          <Route path='/resume' component={Resume} />
        </Switch>
        <Footer />
      </Container>
    </Router>
  );
}

export default App;