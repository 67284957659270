import React from 'react';
import Grid from '@mui/material/Grid';
import Project from './Project';

import fireflyCafeImg from '../images/firefly-cafe.png';
import fliprImg from '../images/FLIPR.png';
import matchMadeInHeavenImg from '../images/match-made-in-heaven.png';
import techBlogImg from '../images/tech-blog.png';
import weatherDashboardImg from '../images/weather-dashboard.png';
import wrteImg from '../images/wrte.png';

function Portfolio () {
  const projects = [
    {
      id: 1,
      title: 'Firefly Cafe',
      image: fireflyCafeImg,
      liveLink: 'https://firefly-cafe.herokuapp.com/',
      codeLink: 'https://github.com/oliviasylee/firefly-cafe',
    },
    {
      id: 2,
      title: 'FLIPR',
      image: fliprImg,
      liveLink: 'https://vast-thicket-59456.herokuapp.com/',
      codeLink: 'https://github.com/oliviasylee/FLIPR',
    },
    {
      id: 3,
      title: 'The Match Made in Heaven',
      image: matchMadeInHeavenImg,
      liveLink: 'https://oliviasylee.github.io/match-made-in-heaven/',
      codeLink: 'https://github.com/oliviasylee/match-made-in-heaven',
    },
    {
      id: 4,
      title: 'Tech Blog',
      image: techBlogImg,
      liveLink: 'https://tech-blog-oslee.herokuapp.com/',
      codeLink: 'https://github.com/oliviasylee/tech-blog',
    },
    {
      id: 5,
      title: 'Weather Dashboard',
      image: weatherDashboardImg,
      liveLink: 'https://oliviasylee.github.io/weather-dashboard/',
      codeLink: 'https://github.com/oliviasylee/weather-dashboard',
    },
    {
      id: 6,
      title: 'Write Now! Text Editor',
      image: wrteImg,
      liveLink: 'https://wnte-oslee.herokuapp.com/',
      codeLink: 'https://github.com/oliviasylee/write-now-text-editor',
    },
  ];
  
  return (
    <Grid container spacing={2}>
      {projects.map((project) => (
        <Grid key={project.id} item xs={12} sm={6} md={4}>
          <Project project={project} />
        </Grid>
      ))}
    </Grid>
  );
}

export default Portfolio;