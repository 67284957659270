import React from 'react';
import Grid from '@mui/material/Grid';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

function Footer () {
  return (
    <footer className='footer' style={{ borderTop: '1px solid black'}}>
      <Grid container>
        <Grid item xs={12}>
          <ul style={{ marginTop: 30, marginBottom: 0 }} className='socialIcons'>
            <li>
              <a href='https://github.com/oliviasylee' target='_blank' rel='noopener noreferrer'>
                <GitHubIcon fontSize='large' style={{ color: 'black' }}>
                </GitHubIcon>
              </a>
            </li>
            <li>
              <a href='https://www.linkedin.com/in/oliviasylee/' target='_blank' rel='noopener noreferrer'>
                <LinkedInIcon fontSize='large' style={{ color: 'black' }}>
                </LinkedInIcon>
              </a>
            </li>
            <li>
              <a href='#'>
                <TwitterIcon fontSize='large' style={{ color: 'black' }}>
                </TwitterIcon>
              </a>
            </li>
          </ul>
        </Grid>
          <Grid item xs={12}>
            <p className='footerText'>Designing with heart & mind from Atlanta, GA, <br /> 
            fueled by iced latte ☕ and cats 🐈 < br/><br />
            Copyright ©2023 Olivia Seonyeong Lee. All rights reserved. < br/>
            </p>
          </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;