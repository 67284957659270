import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/joy/Button';
import { Chip, Link, Typography } from '@mui/material';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  box: {
    padding: '10px',
    height: '400px',
  },
  leftBox: {
    borderRight: '1px solid #212121',
    flex: 1,
  },
  rightBox: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'left',
  },
  button: {
    color: 'black',
    backgroundColor: '#de9151ff',
    '&:hover': {
      backgroundColor: '#ad9e91',
      transform: 'scale(1.02)',
      color: 'white',
    },
  },
};

function Resume() {
  const frontendTechs = [
    'HTML', 'CSS', 'JavaScript', 'jQuery', 'Bootstrap', 'PWA', 'React'
  ];

  const backendTechs = [
    'Node.js', 'Express', 'MySQL', 'Sequelize', 'MongoDB', 'Mongoose', 'GraphQL'
  ];

  return (
    <Grid container style={styles.container}>
      <Grid item xs={12} md={6}>
        <Box sx={{ ...styles.box, ...styles.leftBox }}>
        <Typography variant='h5'>Things I'm familiar with</Typography><br /><br />
          <Typography variant='h6' gutterBottom>Frontend</Typography>
          {frontendTechs.map((tech, index) => (
            <Chip
              key={index}
              label={tech}
              variant='outlined'
              sx={{ marginRight: '5px', marginBottom: '5px' }}
              size='large'
            />
          ))}<br /><br /><br />
          <Typography variant='h6' gutterBottom>Backend</Typography>
          {backendTechs.map((tech, index) => (
            <Chip
              key={index}
              label={tech}
              variant='outlined'
              sx={{ marginRight: '5px', marginBottom: '5px' }}
              size='large'
            />
          ))}
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box sx={{ ...styles.box, ...styles.rightBox }}>
          <Link 
            href='/my-react-portfolio/images/resume.pdf' 
            target='_blank' 
            rel='noreferrer' 
            style={{ textDecoration: 'none' }}>
              <Button 
                size='lg' 
                variant='contained' 
                sx={styles.button}>
                Download My Resume
              </Button>
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
}


export default Resume;