import React from 'react';
import Grid from '@mui/material/Grid';
import styled, { keyframes } from 'styled-components';
import { flash } from 'react-animations';
import osleeImg from '../images/oslee.png'

const Flash = styled.h2`animation: 3s ${keyframes `${flash}`} infinite`;

function AboutMe() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <img alt='Olivia Seonyeong Lee' className='pfp' src={osleeImg}></img>
      </Grid>
      <Grid item xs={12} sm={8} className='heroText'>
        <Flash>Hi there 👋 I’m Olivia! 👩🏻‍💻</Flash>
        <p style={{ fontSize: 20 }}>
        I’m an aspiring Atlanta-based full-stack developer and Web Consultant with a background in Business. <br /><br />
        As I delved into web development, I discovered that I am an intuitive and creative thinker. I use these qualities to quickly understand projects and find efficient solutions. I also enjoy learning new things and constantly strive to grow. <br /><br />
        <b>I'm excited to meet you in this vast world of the web!</b>
        </p>
      </Grid>
    </Grid>
  );
}

export default AboutMe;
